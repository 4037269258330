import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-leave-business-structure-popup',
  templateUrl: './leave-business-structure-popup.component.html',
  styleUrls: ['./leave-business-structure-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaveBusinessStructurePopupComponent {
  constructor(private __dialogRef: MatDialogRef<LeaveBusinessStructurePopupComponent>) {}

  submitResult(result: boolean): void {
    this.__dialogRef.close(result);
  }
}
