<div class="wrapper d-flex align-items-center">
  <div class="wrapper_caption d-flex align-items-center">
    <img src="assets/icons/error.svg" alt="warning" />
    <div>Leave Page?</div>
  </div>
  <div class="wrapper_info">Leaving now will discard any unsaved changes. Are you sure you want to leave this page?</div>
  <div>
    <button type="button" mat-stroked-button color="outline" class="ms-12" (click)="submitResult(false)">Cancel</button>
    <button type="button" mat-raised-button class="ms-12" (click)="submitResult(true)">Leave</button>
  </div>
</div>
